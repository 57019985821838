<template>
    <section>
        <!-- 选择栏 -->
        <el-col :span="24" class="toolbar" style="padding-bottom: 0; padding-left: 5px;" >
            <el-form :inline="true" :model="filters">
                <el-row>
                    <el-form-item label="推广账号" prop="advAccountName" >
                        <el-select size="small" v-model="filters.advAccountName" value="" style="width:140px" filterable clearable>
                            <el-option v-for="item in bindAdvAccountOptions" :key="item.value" :value="item.value"
                                :label="item.label"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="客户账号" prop="guestAccountId">
                        <el-select size="small" v-model="filters.guestAccountId" value="" style="width:140px" filterable clearable>
                            <el-option v-for="item in guestAccountOptions" :key="item.value" :value="item.value"
                                :label="item.label"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="运营账号" prop="staffAccountId" v-if="gconfig.isAdmin()" >
                        <el-select size="small" v-model="filters.staffAccountId" value="" style="width:180px" filterable clearable>
                            <el-option v-for="item in staffAccountOptions" :key="item.value" :value="item.value"
                                :label="item.label"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="平台" prop="platformId" >
                        <el-select size="small" v-model="filters.platformId" value="" style="width:140px" filterable clearable>
                            <el-option v-for="item in platformOptions" :key="item.value" :value="item.value"
                                :label="item.label"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="域名" prop="domainName" >
                        <el-input size="small" v-model="filters.domainName" clearable style="width:140px"></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" size="small" v-on:click="handleDomainList()">查询</el-button>
                        <el-button size="small" v-on:click="handleAdd()" v-if="!gconfig.isGuest()">添加</el-button>
                    </el-form-item>
                </el-row>

                <el-row style="margin-top:-10px">
                    <el-form-item>
                        <el-button size="mini" type="primary" :disabled="selections.length==0" v-on:click="showBatchModify('normalpage')">批量修改普通页</el-button>
                    </el-form-item>
                    <el-form-item>
                        <el-button size="mini" type="primary" :disabled="selections.length==0" v-on:click="showBatchModify('examinepage')">批量修改审核页</el-button>
                    </el-form-item>
                    <el-form-item>
                        <el-button size="mini" type="primary" :disabled="selections.length==0" v-on:click="showBatchModify('resultpage')">批量修改结果页</el-button>
                    </el-form-item>
                    <el-form-item>
                        <el-button size="mini" type="primary" :disabled="selections.length==0" v-on:click="showBatchModify('shieldip')">批量修改屏蔽ip</el-button>
                    </el-form-item>
                    <el-form-item>
                        <el-button size="mini" type="primary" :disabled="selections.length==0" v-on:click="showBatchModify('shieldterminal')">批量修改屏蔽终端</el-button>
                    </el-form-item>

                    <el-form-item label="" style="">
                        <el-checkbox-group v-model="checkedColumns" @change="setCheckedColumns">
                        <el-checkbox v-for="column in this.columnGroups" :label="column" :key="column">{{ column }}</el-checkbox>
                        </el-checkbox-group>
                    </el-form-item>
                </el-row>
            </el-form>
        </el-col>

        <!-- 列表 -->
        <template>
            <el-table :data="dataList" stripe highlight-current-row v-loading="dataLoading" size="small" height="775"
                style="width: 100%;" @selection-change="selectionChange" >
                <el-table-column type="selection" width="55"></el-table-column>

                <el-table-column prop="adv_account" label="推广账号" width="150">
                    <template slot-scope="scope">
                        <span v-if="scope.row.adv_account== ''">未绑定</span>
                        <span v-else style="color:green; font-weight: bold;" >{{ scope.row.adv_account }} </span>
                    </template>
                </el-table-column>
                <el-table-column prop="account_id_guest" label="客户账号" width="140">
                    <template slot-scope="scope">
                        <span v-if="scope.row.account_id_guest == 0">未绑定</span>
                        <span v-else style="color:green; font-weight: bold;" >{{ formatGuestAccountName(scope.row.account_id_guest) }} </span>
                    </template>
                </el-table-column>
                <el-table-column prop="account_id_staff" label="运营账号" width="140">
                    <template slot-scope="scope">
                        <span v-if="scope.row.account_id_staff == 0">未绑定</span>
                        <span v-else style="color:orange; font-weight: bold;" >{{ formatStaffAccountName(scope.row.account_id_staff) }} </span>
                    </template>
                </el-table-column>
                <el-table-column prop="platform" label="平台" width="100" :formatter="formatPlatform" ></el-table-column>
                <el-table-column prop="normal_page_url" label="普通页" width="150" :show-overflow-tooltip="true">
                    <template slot-scope="scope">
                        <a target="_blank" :href="scope.row.normal_page_url">{{ scope.row.normal_page_url }}</a>
                    </template>
                </el-table-column>
                <el-table-column prop="result_page_id" label="结果页" width="100" :show-overflow-tooltip="true" :formatter="formatResultPage"></el-table-column>
                <el-table-column prop="shield_conf" label="屏蔽配置" width="200" :show-overflow-tooltip="true"></el-table-column>
                <el-table-column prop="domain" label="域名" width="180"></el-table-column>

                <el-table-column v-if="checkedColumns.includes('其他')" prop="examine_page_url" label="审核页" width="150" :show-overflow-tooltip="true">
                    <template slot-scope="scope">
                        <a target="_blank" :href="scope.row.examine_page_url">{{ scope.row.examine_page_url }}</a>
                    </template>
                </el-table-column>
                <el-table-column v-if="checkedColumns.includes('其他')" prop="shield_page_url" label="推广页" width="150" :show-overflow-tooltip="true">
                    <template slot-scope="scope">
                        <a target="_blank" :href="scope.row.shield_page_url">{{ scope.row.shield_page_url }}</a>
                    </template>
                </el-table-column>
                <el-table-column v-if="checkedColumns.includes('其他')" prop="dyn_script" label="动态脚本" width="120" :show-overflow-tooltip="true"></el-table-column>
                <el-table-column v-if="checkedColumns.includes('其他')" prop="create_time" label="创建时间" width="140" :formatter="formatTime"></el-table-column>
                <el-table-column v-if="checkedColumns.includes('其他')" prop="app_key" label="key/token" width="100" :show-overflow-tooltip="true"></el-table-column>
                <el-table-column v-if="checkedColumns.includes('其他')" prop="app_secret" label="secret" width="100" :show-overflow-tooltip="true"></el-table-column>
                <el-table-column v-if="checkedColumns.includes('其他')" prop="jzqs" label="jzqs" width="100" :show-overflow-tooltip="true"></el-table-column>
                <el-table-column v-if="checkedColumns.includes('其他')" prop="port" label="端口" width="50"></el-table-column>
                <!-- <el-table-column v-if="checkedColumns.includes('其他')" prop="spec_form" label="特定表单" width="70"></el-table-column> -->
                <el-table-column v-if="checkedColumns.includes('其他')" prop="spec_form_feedback_rate" label="全部表单回传比例" width="120">
                    <template slot-scope="scope">
                        <span>{{scope.row.spec_form_feedback_rate}}%</span>
                    </template>
                </el-table-column>
                <el-table-column v-if="checkedColumns.includes('其他')" prop="company_name" label="公司名字" width="120" :show-overflow-tooltip="true"></el-table-column>
                <el-table-column v-if="checkedColumns.includes('其他')" prop="company_addr" label="公司地址" width="120" :show-overflow-tooltip="true"></el-table-column>
                <el-table-column v-if="checkedColumns.includes('其他')" prop="company_phone" label="公司电话" width="120"></el-table-column>
                <el-table-column v-if="checkedColumns.includes('其他')" prop="desc" label="描述" width="120" :show-overflow-tooltip="true"></el-table-column>

                <el-table-column label="操作" min-width="240" fixed="right">
                    <template slot-scope="scope">
                        <el-button size="small" @click="handleEdit(scope.row)" style="width: 50px; margin-left: 3px;">修改</el-button>
                        <el-button v-if="gconfig.isAdmin()" type="danger" size="small" @click="handleDelete(scope.row)" style="width: 50px; margin-left: 3px;">删除</el-button>
                        <!-- <el-button size="small" @click="handleRule(scope.row)"  -->
                        <!-- :style="'width: 80px; margin-left:3px;font-weight:bold;' + (scope.row.feedback_rule==1 ? 'color:green' : 'color:gray')"> 回传规则 </el-button> -->
                        <!-- <el-button size="small" @click="handleFeedbackDealOrder(scope.row)" 
                        :style="'width: 80px; margin-left: 3px;font-weight:bold;'+(scope.row.feedback_deal_order==1 ? 'color:green' : 'color:gray')">成交回传</el-button> -->
                        <span style="margin-left: 3px; font-weight:bold;">回传规则:</span><el-switch :value="scope.row.feedback_rule==1" @change="function(flag) { onFeedbackRuleChange(flag, scope.row); }"></el-switch>
                        <span style="margin-left: 3px; font-weight:bold;">成交回传:</span><el-switch :value="scope.row.feedback_deal_order==1" @change="function(flag) { onDealFeedbackChange(flag, scope.row); }"></el-switch>
                        <span v-if="gconfig.isAdmin()" style="margin-left: 3px; font-weight:bold;">表单手动回传:
                            <el-switch :value="scope.row.manual_feedback==1" @change="function(flag) { onManualFeedbackChange(flag, scope.row); }"></el-switch>
                        </span>
                        <el-button v-if="checkedColumns.includes('其他')" size="small" @click="handleNginx(scope.row)" style="width: 50px; margin-left: 3px;">nginx</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <!-- 翻页 -->
            <el-col :span="24" class="toolbar">
                <el-pagination layout="total, sizes, prev, pager, next" :current-page="this.page"
                    :total="total"
                    :page-size="pageSize" 
                    @current-change="handleCurrentChange" 
                    @size-change="handleSizeChange"
                    :page-sizes="[100,200,400]"
                    style="float:right;">
                </el-pagination>
            </el-col> 
        </template>

        <!-- batchmodify -->
        <el-dialog :title="batchModifyTitle" :visible.sync="batchModifyVisible" :close-on-click-modal="false" width="30%">
            <el-form :model="batchForm" label-width="100px" ref="batchform" label-position="left">
                <el-col :span="24" v-if="batchForm.modType=='normalpage'">
                    <el-select key="1" size="small" v-model="batchForm.modValue" value="" style="width:70%">
                        <el-option v-for="item in normalPageOptions" :key="item.value" :value="item.value" :label="item.label"></el-option>
                    </el-select>
                </el-col>
                <el-col :span="24" v-if="batchForm.modType=='examinepage'">
                    <el-select key="2" size="small" v-model="batchForm.modValue" value="" style="width:70%">
                        <el-option v-for="item in examinePageOptions" :key="item.value" :value="item.value" :label="item.label"></el-option>
                    </el-select>
                </el-col>
                <el-col :span="24" v-if="batchForm.modType=='resultpage'">
                    <el-select key="3" size="small" v-model="batchForm.modValue" value="" style="width:70%">
                        <el-option v-for="item in resultPageOptions" :key="item.value" :value="item.value" :label="item.label"></el-option>
                    </el-select>
                </el-col>
                <el-col :span="24" v-if="batchForm.modType=='shieldip'">
                    <el-form-item label="IP屏蔽" prop="ipid" label-width="auto">
                        <el-select key="4" size="small" v-model="batchForm.modValue" value="" style="width:70%">
                            <el-option v-for="item in shieldIpOptions" :key="item.value" :value="item.value" :label="item.label"></el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="24" v-if="batchForm.modType=='shieldterminal'">
                    <el-form-item label="终端屏蔽" prop="terminal" label-width="auto">
                        <el-select key="5" size="small" v-model="batchForm.modValue" value="" multiple style="width:70%">
                            <el-option v-for="item in gconfig.terminals" :key="item.value" :value="item.value" :label="item.label"></el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click.native="formCancel('batchform')">取消</el-button>
                <el-button type="primary" @click.native="handleSubmitBatch" :loading="formLoading">提交</el-button>
            </div>
        </el-dialog>

        <!-- nginx -->
        <el-dialog title="nginx配置" :visible.sync="nginxVisible" :close-on-click-modal="false" width="60%">
            <el-input
                type="textarea"
                readonly="true"
                :autosize="{ minRows: 1, maxRows: 100}"
                v-model="nginxConf">
            </el-input>
        </el-dialog>

        <!-- 添加/修改 -->
        <el-dialog title="修改/添加" :visible.sync="formVisible" :close-on-click-modal="false" width="60%">
            <el-form :model="formData" label-width="100px" ref="form" label-position="left">
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="域名" prop="domain" required label-width="auto">
                            <el-input style="width:80%" size="small" v-model="formData.domain" :disabled="formType==2"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="4">
                        <el-form-item label="端口" prop="port" required label-width="auto">
                            <el-input style="width:50%" size="small" v-model.number="formData.port" :disabled="formType==2&&!gconfig.isAdmin()"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="4">
                        <el-form-item label="平台" prop="platform" label-width="auto" required>
                            <el-select size="small" v-model="formData.platform" value="" style="width:60%" :disabled="formType==2&&!gconfig.isAdmin()" filterable>
                                <el-option v-for="item in platformOptions" :key="item.value" :value="item.value" :label="item.label"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>

                    <!--百度/UC-->
                    <div v-if="gconfig.isBaidu(getPlatformName(formData.platform))
                        || gconfig.isUC(getPlatformName(formData.platform))
                        || gconfig.isVivo(getPlatformName(formData.platform))">
                        <el-col :span="5">
                            <el-form-item label="token" prop="app_key" label-width="auto">
                                <el-input style="width:70%" size="small" v-model="formData.app_key" :disabled="formType==2&&gconfig.isGuest()"></el-input>
                            </el-form-item>
                        </el-col>
                    </div>
                    <!--360-->
                    <div v-if="gconfig.is360(getPlatformName(formData.platform))">
                        <el-col :span="5">
                            <el-form-item label="key" prop="app_key" label-width="auto">
                                <el-input style="width:70%" size="small" v-model="formData.app_key" :disabled="formType==2&&gconfig.isGuest()"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="5">
                            <el-form-item label="secret" prop="app_secret" label-width="auto">
                                <el-input style="width:70%" size="small" v-model="formData.app_secret" :disabled="formType==2&&gconfig.isGuest()"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="5">
                            <el-form-item label="jzqs" prop="jzqs" label-width="auto">
                                <el-input style="width:70%" size="small" v-model="formData.jzqs" :disabled="formType==2&&gconfig.isGuest()"></el-input>
                            </el-form-item>
                        </el-col>
                    </div>
                    <el-col :span="5">
                        <el-form-item label="推广账号" prop="adv_account" label-width="auto">
                            <el-select size="small" v-model="formData.adv_account" value="" style="width:60%" clearable filterable>
                                <el-option v-for="item in formatAdvAccount(formData.platform)" :key="item.value" :value="item.value" :label="item.label"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="6">
                        <el-form-item label="普通页" prop="normal_page" label-width="auto">
                            <el-select size="small" v-model="formData.normal_page" value="" style="width:70%" clearable filterable>
                                <el-option v-for="item in normalPageOptions" :key="item.value" :value="item.value" :label="item.label"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="审核页" prop="examine_page" label-width="auto">
                            <el-select size="small" v-model="formData.examine_page" value="" style="width:70%" clearable filterable>
                                <el-option v-for="item in examinePageOptions" :key="item.value" :value="item.value" :label="item.label"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="结果页" prop="result_page_id" label-width="auto" required>
                            <el-select size="small" v-model="formData.result_page_id" value="" style="width:70%" filterable>
                                <el-option v-for="item in resultPageOptions" :key="item.value" :value="item.value" :label="item.label"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="公司名字" prop="company_name" label-width="auto">
                            <el-input style="width:70%" size="small" v-model="formData.company_name"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="公司地址" prop="company_addr" label-width="auto">
                            <el-input style="width:70%" size="small" v-model="formData.company_addr"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="公司电话" prop="company_phone" label-width="auto">
                            <el-input style="width:70%" size="small" v-model="formData.company_phone"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="域名描述" prop="desc" label-width="auto">
                            <el-input style="width:70%" size="small" v-model="formData.desc"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="5">
                        <el-form-item label="IP屏蔽" prop="ipid" label-width="auto">
                            <el-select size="small" v-model="formData.shield_conf_js.ipid" value="" style="width:60%" clearable filterable>
                                <el-option v-for="item in shieldIpOptions" :key="item.value" :value="item.value" :label="item.label"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="7">
                        <el-form-item label="终端屏蔽" prop="terminal" label-width="auto">
                            <el-select size="small" v-model="formData.shield_conf_js.terminal" value="" style="width:70%" multiple clearable filterable>
                                <el-option v-for="item in gconfig.terminals" :key="item.value" :value="item.value" :label="item.label"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="城市屏蔽" prop="city" label-width="auto">
                            <el-select size="small" v-model="formData.shield_conf_js.city" value="" style="width:70%" multiple clearable filterable>
                                <el-option v-for="item in gconfig.getCityOptions()" :key="item.value" :value="item.value" :label="item.label"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <!--非UC有效-->
                <el-row v-if="!gconfig.isUC(getPlatformName(formData.platform))">
                    <!-- <el-col :span="6">
                        <el-form-item label="特定表单" prop="spec_form" label-width="auto">
                            <el-select size="small" v-model="formData.spec_form" value="" style="width:50%" clearable filterable>
                                <el-option v-for="item in orderFormOptions" :key="item.value" :value="item.value" :label="item.label"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col> -->
                    <el-col :span="6">
                        <el-form-item label="全部表单回传比例" prop="terminal" label-width="auto">
                            <el-input style="width:20%" size="small" v-model.number="formData.spec_form_feedback_rate"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="动态脚本" prop="terminal" label-width="auto">
                            <el-input type="textarea" style="width:80%"
                                :autosize="{ minRows: 1, maxRows: 100}"
                                v-model="formData.dyn_script">
                            </el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click.native="formCancel('form')">取消</el-button>
                <el-button type="primary" @click.native="handleSubmit" :loading="formLoading">提交</el-button>
            </div>
        </el-dialog>
    </section>
</template>

<script>
import util from "../../common/js/util";
import gconfig from "../../api/gconfig";
import model from "../../common/js/model";
import { postRequest, formatTimestamp} from "../../api/api";

export default {
    data() {
        return {
            filters: {
                advAccountName: "",
                staffAccountId: "",
                guestAccountId: "",
                domainName: "",
                platformId: "",
            },

            page: 1,
            pageSize: 100,
            total: 1,
            dataList: [],
            dataLoading: false,

            formVisible: false,
            formLoading: false,
            formType: 0,

            nginxVisible: false,
            nginxConf: "",

            formData: {
                domain: "",
                port: 0,
                platform: "",
                app_key: "",
                app_secret: "",
                jzqs: "",
                normal_page: "",
                examine_page: "",
                desc: "",
                company_name: "",
                company_addr: "",
                company_phone: "",
                create_time: 0,
                account_id_staff: 0,
                account_id_guest: 0,
                shield_conf: "",
                result_page_id: "",
                spec_form: "",
                spec_form_feedback_rate: 0,
                dyn_script: "",
                adv_account: "",

                normal_page_url: "",
                examine_page_url: "",
                shield_page_url: "", //推广页
                shield_conf_js: {terminal:[], city:[]}
            },

            normalPageOptions: [],
            examinePageOptions: [],
            platformOptions: [],
            gconfig: gconfig,

            cityOptions: gconfig.getCityOptions(),
            shieldIpOptions: [],
            resultPageOptions: [],
            orderFormOptions: [
                {label:"form1", value: "form1"},
                {label:"form2", value: "form2"},
                {label:"form3", value: "form3"},
                {label:"form4", value: "form4"},
            ],

            bindAdvAccountOptions: [],
            allAdvAccountOptions: [],
            guestAccountOptions: [],
            staffAccountOptions: [],

            batchForm: {
                modType: "",
                modValue: "",
            },
            batchModifyTitle: "",
            batchModifyVisible: false,

            selections: [],
            columnGroups: ['其他'],
            checkedColumns: [],
        }
    },

    methods: {
        formatTime(row, column, cellValue) {
            if (cellValue == 0) {
                return "0000-00-00 00:00:00";
            }
            return formatTimestamp(cellValue);
        },

        formatPlatform(row, column, cellValue) {
            for (let item of this.platformOptions) {
                if (item.value == cellValue) {
                    return item.label;
                }
            }
            return "";
        },

        getPlatformName(platformId) {
            for (let item of this.platformOptions) {
                if (item.value == platformId) {
                    return item.label;
                }
            }
            return ""
        },

        formatResultPage(row, column, cellValue) {
            for (let item of this.resultPageOptions) {
                if (cellValue == item.value) {
                    return item.label;
                }
            }
            return "";
        },

        formatGuestAccountName(accountId) {
            for (let item of this.guestAccountOptions) {
                if (accountId == item.value) {
                    return item.label;
                }
            }
            return "";
        },
        formatStaffAccountName(accountId) {
            for (let item of this.staffAccountOptions) {
                if (accountId == item.value) {
                    return item.label;
                }
            }
            return "";
        },

        formatAdvAccount(platform) {
            let isBaidu = gconfig.isBaidu(this.getPlatformName(platform));
            let opts = [];
            for (let item of this.allAdvAccountOptions) {
                if (isBaidu) {
                    if (gconfig.isBaidu(this.getPlatformName(item.platform))) {
                        opts.push(item);
                    }
                } else {
                    opts.push(item);
                }
            }
            console.log("formatAdvAccount###", opts, platform);
            return opts;
        },

        selectionChange(selections) {
            this.selections = selections;
        },

        showBatchModify(type) {
            this.batchForm.modType = type;
            this.batchForm.modValue = "";
            if (type == "normalpage") {
                this.batchModifyTitle = "批量修改普通页";
            } else if (type == "examinepage") {
                this.batchModifyTitle = "批量修改审核页";
            } else if (type == "resultpage") {
                this.batchModifyTitle = "批量修改结果页";
            } else if (type == "shieldip") {
                this.batchModifyTitle = "批量修改屏蔽ip";
            } else if (type == "shieldterminal") {
                this.batchModifyTitle = "批量修改屏蔽终端";
                this.batchForm.modValue = [];
            }
            this.batchModifyVisible = true;
        },

        handleDomainList() {
            let params = Object.assign({}, this.filters);
            params.page = this.page;
            params.pageSize = this.pageSize;

            this.dataLoading = true;
            let queryModel = model.GetQueryModel("DomainMgrCtrl", "GetDomainList", params);
            postRequest(queryModel).then(res => {
                this.dataLoading = false;

                if (res.data.msg !== "") {
                    this.$message.warning(res.data.msg);
                    return;
                }
                if (this.page === 1) {
                    this.total = res.data.total;
                }
                this.dataList = res.data.datas;
                console.log("GetDomainList", this.dataList);

            });
        },

        handlePageList() {
            let params = { page:1, pageSize:1000 };
            let queryModel = model.GetQueryModel("PageMgrCtrl", "GetPageList", params);
            postRequest(queryModel).then(res => {
                if (res.data.msg !== "") {
                    this.$message.warning(res.data.msg);
                    return;
                }
                for (let page of res.data.datas) {
                    if (page.page_type == 0) {
                        this.normalPageOptions.push({label:page.page_name, value:page.page_name}); //普通页
                    } else {
                        this.examinePageOptions.push({label:page.page_name, value:page.page_name}); //审核页
                    }
                }
                console.log("GetPageList", this.normalPageOptions, this.examinePageOptions);

            });
        },

        handleShieldIpList() {
            let params = {}
            let queryModel = model.GetQueryModel("ShieldIpCtrl", "GetShieldIpOptions", params);
            postRequest(queryModel).then(res => {
                if (res.data.msg !== "") {
                    this.$message.warning(res.data.msg);
                    return;
                }
                this.shieldIpOptions = res.data.datas;
                console.log("handleShieldIpList", this.shieldIpOptions);
            });
        },

        handleResultPageList() {
            let params = {
                page: 1,
                pageSize: 1000,
            }
            let queryModel = model.GetQueryModel("ResultPageCtrl", "GetList", params);
            postRequest(queryModel).then(res => {
                if (res.data.msg !== "") {
                    this.$message.warning(res.data.msg);
                    return;
                }
                for (let v of res.data.datas) {
                    this.resultPageOptions.push({label:v.name, value:v.id})
                }
                console.log("handleResultPageList", res);
            });
        },

        handleNginx(row) {
            let params = {
                domain: row.domain,
            };
            let queryModel = model.GetQueryModel("DomainMgrCtrl", "GetNginxConf", params);
            postRequest(queryModel).then(res => {
                if (res.data.msg !== "") {
                    this.$message.warning(res.data.msg);
                    return;
                }
                this.nginxConf = res.data.datas;
                this.nginxVisible = true;
                console.log("GetNginxConf", res.data.datas);
            });
        },

        handleSubmit() {
            this.$refs.form.validate(valid => {
                if (valid) {
                    this.$confirm("确认提交吗？", "提示", {}).then(() => {
                        this.formLoading = true;
                        let params = Object.assign({}, this.formData);
                        params.shield_conf = JSON.stringify(params.shield_conf_js);
                        var queryModel = model.GetQueryModel("DomainMgrCtrl", "UpdateDomain", params);
                        postRequest(queryModel).then(res => {
                            this.formLoading = false;
                            if (!util.isEmpty(res.data.msg)) {
                                this.$message.warning(res.data.msg);
                                return;
                            } 
                            this.$message.success("操作成功");
                            this.$refs.form.resetFields();
                            this.formVisible = false;
                            this.handleDomainList();
                        });
                    });
                }
            });
        },

        handleSubmitBatch() {
            this.$refs.batchform.validate(valid => {
                if (valid) {
                    this.$confirm("确认提交吗？", "提示", {}).then(() => {
                        this.formLoading = true;
                        let params = Object.assign({}, this.batchForm);
                        params.selections = [];
                        for (let v of this.selections) {
                            params.selections.push(v.domain);
                        }
                        var queryModel = model.GetQueryModel("DomainMgrCtrl", "BatchModifyDomain", params);
                        postRequest(queryModel).then(res => {
                            this.formLoading = false;
                            if (!util.isEmpty(res.data.msg)) {
                                this.$message.warning(res.data.msg);
                                return;
                            } 
                            let notice = "成功:"+res.data.datas.succ_num+" 失败:"+res.data.datas.fail_num
                            this.$message.success(notice);
                            this.$refs.batchform.resetFields();
                            this.batchModifyVisible = false;
                            this.handleDomainList();
                        });
                    });
                }
            });
        },

        handleAdd() {
            this.formType = 1;
            this.formVisible = false;
            this.formLoading = false;
            this.formData = {
                domain: "",
                port: 80,
                platform: "",
                app_key: "",
                app_secret: "",
                jzqs: "",
                normal_page: "",
                examine_page: "",
                desc: "",
                company_name: "",
                company_addr: "",
                company_phone: "",
                create_time: 0,
                account_id_staff: 0,
                account_id_guest: 0,
                shield_conf: "",
                result_page_id: "",
                spec_form: "",
                spec_form_feedback_rate: 100,
                dyn_script: "",
                adv_account: "",

                normal_page_url: "",
                examine_page_url: "",
                shield_page_url: "", //推广页
                shield_conf_js: {terminal:[], city:[], ipid:0}
            },
            this.formVisible = true;
        },

        handleEdit(row) {
            this.formType = 2;
            this.formVisible = false;
            this.formLoading = false;
            row.shield_conf_js = row.shield_conf ? JSON.parse(row.shield_conf) : {terminal:[], city:[], ipid:0};
            Object.assign(this.formData, row)
            this.formVisible = true;
        },

        onFeedbackRuleChange: function (flag, row) {
            row.feedback_rule = flag ? 1 : 0;
            this.handleRule(row);
        },

        handleRule: function (row) {
            let params = {
                domain: row.domain,
                feedback_rule: row.feedback_rule,
            };
            var queryModel = model.GetQueryModel("DomainMgrCtrl", "FeedbackRule", params);
            postRequest(queryModel).then(res => {
                if (!util.isEmpty(res.data.msg)) {
                    this.$message({ message: res.data.msg, type: "warning" });
                    return;
                } 
                this.handleDomainList();
            });
        },

        onDealFeedbackChange: function (flag, row) {
            row.feedback_deal_order = flag ? 1 : 0;
            this.handleFeedbackDealOrder(row);
        },

        handleFeedbackDealOrder: function (row) {
            let params = {
                domain: row.domain,
                feedback_deal_order: row.feedback_deal_order,
            };
            var queryModel = model.GetQueryModel("DomainMgrCtrl", "FeedbackDealOrder", params);
            postRequest(queryModel).then(res => {
                if (!util.isEmpty(res.data.msg)) {
                    this.$message({ message: res.data.msg, type: "warning" });
                    return;
                } 
                this.handleDomainList();
            });
        },

        onManualFeedbackChange: function (flag, row) {
            row.manual_feedback = flag ? 1 : 0;
            this.handleManualFeedback(row);
        },

        handleManualFeedback: function (row) {
            let params = {
                domain: row.domain,
                manual_feedback: row.manual_feedback,
            };
            var queryModel = model.GetQueryModel("DomainMgrCtrl", "ManualFeedback", params);
            postRequest(queryModel).then(res => {
                if (!util.isEmpty(res.data.msg)) {
                    this.$message({ message: res.data.msg, type: "warning" });
                    return;
                } 
                this.handleDomainList();
            });
        },

        handleDelete: function (row) {
            this.$confirm("确认删除吗?", "提示", {type: "warning"}).then(() => {
                let params = {
                    domain: row.domain,
                };
                var queryModel = model.GetQueryModel("DomainMgrCtrl", "DeleteDomain", params);
                postRequest(queryModel).then(res => {
                    if (!util.isEmpty(res.data.msg)) {
                        this.$message({ message: res.data.msg, type: "warning" });
                        return;
                    } 
                    this.$message.success("删除成功");
                    this.handleDomainList();
                });
            });
        },

        formCancel(ref) {
            if (ref == "form" ){
                this.formVisible = false;
            } else if (ref == "batchform") {
                this.batchModifyVisible = false;
            }
            this.$refs[ref].resetFields();
        },

        handleCurrentChange(page) {
            this.page = page;
            this.handleDomainList();
        },

        handleSizeChange(val) {
            this.pageSize = val;
            this.handleDomainList();
        },


        handleBindAdvAccountList() {
            let params = {}
            let queryModel = model.GetQueryModel("AdvAccountCtrl", "GetBindAdvAccountList", params);
            postRequest(queryModel).then(res => {
                if (res.data.msg !== "") {
                    this.$message.warning(res.data.msg);
                    return;
                }
                for (let val of res.data.datas) {
                    this.bindAdvAccountOptions.push({label:val.uc_name, value:val.uc_name, platform:val.platform});
                }
                console.log("handleBindAdvAccountList", this.bindAdvAccountOptions);

            });
        },

        handleGuestAccountList() {
            let params = {};
            let queryModel = model.GetQueryModel("AccountMgrCtrl", "GetGuestAccountList", params);
            postRequest(queryModel).then(res => {
                if (res.data.msg !== "") {
                    this.$message.warning(res.data.msg);
                    return;
                }
                for (let val of res.data.datas) {
                    this.guestAccountOptions.push({label:val.name, value:val.id});
                }
                console.log("handleGuestAccountList", this.guestAccountOptions);

            });
        },

        handleStaffAccountList() {
            let params = {};
            let queryModel = model.GetQueryModel("AccountMgrCtrl", "GetStaffAccountList", params);
            postRequest(queryModel).then(res => {
                if (res.data.msg !== "") {
                    this.$message.warning(res.data.msg);
                    return;
                }
                for (let val of res.data.datas) {
                    this.staffAccountOptions.push({label:val.name, value:val.id});
                }
                console.log("handleStaffAccountList", this.staffAccountOptions);

            });
        },

        setCheckedColumns() {
            window.localStorage.setItem('domainmgr_checked_columns', JSON.stringify(this.checkedColumns));
        },
        getCheckedColumns() {
            this.checkedColumns =  JSON.parse(window.localStorage.getItem("domainmgr_checked_columns")) || [];
        },

        handleAllAdvAccountList() {
            let params = {
                page: 1,
                pageSize: 99999
            }
            let queryModel = model.GetQueryModel("AdvAccountCtrl", "GetList", params);
            postRequest(queryModel).then(res => {
                if (res.data.msg !== "") {
                    this.$message.warning(res.data.msg);
                    return;
                }
                for (let val of res.data.datas) {
                    this.allAdvAccountOptions.push({label:val.uc_name, value:val.uc_name, platform:val.platform});
                }

            });
        },
    },
    mounted() {
        this.getCheckedColumns()

        this.dataLoading = true;
        util.getPlatformOptions(this).then(res => {
            this.dataLoading = false;
            this.platformOptions = res;

            this.handlePageList();
            this.handleShieldIpList();
            this.handleResultPageList();

            this.handleGuestAccountList();
            this.handleStaffAccountList();
            this.handleBindAdvAccountList();
            this.handleAllAdvAccountList();

            this.handleDomainList();
        });

    }
}
</script>
<style scoped>
</style>